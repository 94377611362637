import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AccountService from '../services/account'

export default function EmailVerification() {
  const { token } = useParams();

  const [validationError, setValidationError] = useState(null)

  useEffect(() => {
    AccountService.verifyEmailToken(token)
      .then(([err, resp]) => {
        if (err) {
          console.error(err)
          setValidationError(err.statusText)
        }
      })
  }, [token])

  return validationError ?
    <div style={{textAlign:"center"}}>
      <h1>😡 Failed to validate token</h1>
      <pre>{validationError}</pre>
      <p>Please contact a site administrator</p>
    </div>
    :
    <div style={{textAlign:"center"}}>
      <h1>😊 Email confirmed ! Thank you</h1>
    </div>
}
