import axios from 'axios'
import { handleResponse } from './helpers'

const AuthService = {

  signup(username, email, password) {
    return handleResponse(
      axios.post('/api/signup', {username, email, password})
    )
  },

  async signin(email, password) {
    return handleResponse(
      axios.post('/api/auth/signin', {email, password})
    )
  },

  async signout() {
    return handleResponse(
      axios.get('/api/auth/signout')
    )
  },

  async status() {
    return handleResponse(
      axios.get('/api/auth/status')
    )
  },

}

export default AuthService
