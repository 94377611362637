import './VideoCard.css'
import React, { Fragment, useState, useEffect } from 'react'
import { Button, ButtonGroup, Card, Container, Row } from 'react-bootstrap'
import { Redirect } from 'react-router'
import Config from '../config'
import VideosService from '../services/videos'

const TITLE_MAX_LEN = 30

function Catalog(props) {
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(null)
  const [videos, setVideos] = useState([])

  useEffect(() => {
    // TODO use https://react-query.tanstack.com/overview
    VideosService.list()
      .then(([err, resp]) => {
        if (err) {
          console.error(err)
          setRedirect('/error')
        }
        else {
          setLoading(false)
          setVideos(resp.data)
        }
      })
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  function renderList(data) {
    return (
      <Container fluid>
        <ul>
          <Row>
            {data.map(item => (
              <li key={item.md5} style={{ listStyle: 'none' }}>
                <VideoCard
                  title={item.title.length <= TITLE_MAX_LEN ? item.title :
                      item.title.substring(0, TITLE_MAX_LEN) + '…'}
                  username={item.username}
                  description={item.description}
                  duration={sexagesimalString(item.durationSeconds)}
                  imgSrc={`${Config.imgUrl}/${item.md5}.jpeg`}
                  gifSrc={`${Config.imgUrl}/${item.md5}.gif`}
                />
              </li>
            ))}
          </Row>
        </ul>
      </Container>
    )
  }

  return (
    <Fragment>
      {loading ? 'Loading…' : renderList(videos)}
    </Fragment>
  )
}

function sexagesimalString(seconds) {
  if (seconds >= 86400)
    throw new Error('Duration overflow')
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getSeconds()
  let timeStr = hh ? hh.toString().padStart(2, '0') + ':' : ''
  timeStr += mm.toString().padStart(2, '0')
    + ':' + ss.toString().padStart(2, '0')
  return timeStr
}


const VideoCard = (props) => {
  return (
    <div className="card-wrapper">
      <Card style={{ width: '100%' }} className="shadow-sm hover-zoom">
        <Card.Img
          variant="top"
          src={props.imgSrc}
          onMouseOver={e => e.currentTarget.src = props.gifSrc}
          onMouseOut={e => e.currentTarget.src = props.imgSrc}
        />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{props.username}</Card.Subtitle>
          <div className="d-flex justify-content-between align-items-center">
            <ButtonGroup aria-label="TODO">
              <Button variant="outline-secondary" size="sm">Download</Button>
              <Button variant="outline-secondary" size="sm">View</Button>
            </ButtonGroup>
            <small className="text-muted">{props.duration}</small>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Catalog
