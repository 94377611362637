import axios from 'axios'
import { handleResponse } from './helpers'

const VideosService = {

  upload(file, metadata, onUploadProgress) {
    let formData = new FormData()
    formData.append('videos', file, file.name)
    formData.append('metadata', JSON.stringify(metadata))

    return axios.post('/api/videos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },

  async list() {
    return handleResponse(
      axios.get('/api/videos')
    )
  },

}

export default VideosService
