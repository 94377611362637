import React, { Suspense, useRef, useState } from 'react'
import { Redirect } from 'react-router'
import { Container, Button, Form, Col, Row } from 'react-bootstrap'

import Notification from './Notification'
import AuthService from '../services/auth'
import { useAuth } from '../contexts/auth'

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'))

function Signup() {
  let auth = useAuth()

  const [response, setResponse] = useState(undefined)
  const [redirect, setRedirect] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validated, setValidated] = useState(false)

  const inputPasswordRef = useRef(null);

  function handleSubmit(event) {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()

      setValidated(true)
    } else {
      AuthService.signup(username, email, password)
        .then(([err, data]) => {
          if (err) {
            if ('status' in err && err.status === 400)
              setResponse(err.data)
            else {
              console.error(err)
              setResponse({message:'Something went wrong'})
            }

          } else {
            const user = data.data
            auth.updateUser(user)
            setRedirect(true)
          }
        })
        .catch(error => {
          setResponse(error)
        })
    }
  }

  if (redirect) {
    return <Redirect to='/' />
  }

  return (
    <Container className="mt-3">
      <Notification data={response}/>

      <div className="mb-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <Form.Group as={Row} controlId="inputUsername">
            <Form.Label column sm={2}>
              Username
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="username"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoFocus={true}
                required minLength="3" maxLength="50"
                pattern="^[A-Za-z]\w{2}\w*$"
              />
              <Form.Control.Feedback type="invalid">
                Username is required, must be at least 3 characters long, can
                only contain ascii letters digits and '_'.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="inputEmail">
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required minLength="9" maxLength="50"
              />
              <Form.Control.Feedback type="invalid">
                Email is required and must be a valid email.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="inputPassword">
            <Form.Label column sm={2}>
              Password
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                ref={inputPasswordRef}
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required minLength="16" maxLength="255"
              />
              <Form.Control.Feedback type="invalid">
                Password is required and must be at least 16 characters long
                and strong enough.
              </Form.Control.Feedback>
              <Suspense fallback={<div>Loading...</div>}>
                <PasswordStrengthBar
                  password={password}
                  minLength={16}
                  onChangeScore={score => {
                    inputPasswordRef.current.setCustomValidity(
                      (score > 2) ? '' : 'Too weak'
                    )
                  }}
                />
              </Suspense>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2 }}>
              <Button id="btn-signup-submit" type="submit">Sign up</Button>
            </Col>
          </Form.Group>

        </Form>
      </div>
    </Container>
  )
}

export default Signup
