const handleResponse = async (axiosCall) => {
  try {
    const response = await axiosCall
    return [null, response.data]

  } catch (error) {
    if (error.response)             // Request made and server responded
      return [error.response, null] // {data,status,headers}

    if (error.request)        // Request made but no response received
      return [error.request, null]

    // Request setup triggered an Error
    console.log('Error', error.message)
    return [error.message, null]
  }
}

export {
  handleResponse,
}
