import React from 'react'
import { Button, Container, Form, InputGroup, Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import AuthService from '../services/auth'
import { useAuth } from '../contexts/auth'

function Header() {
  let auth = useAuth()
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              alt=""
              name="logo"
              src="/screen-play-blue.png"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Castie
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/catalog">
              <Nav.Link id="nav-lnk-catalog">Catalog</Nav.Link>
            </LinkContainer>
            {auth.user && auth.user.authenticated && (
              <LinkContainer to="/upload">
                <Nav.Link id="nav-lnk-upload">Upload</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
          <Form inline>
            <InputGroup className="mr-sm-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
              />
            </InputGroup>
            <AuthButtons />
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}


function AuthButtons() {
  let auth = useAuth()

  function signout() {
    AuthService.signout()
      .then(() => {
        auth.updateUser(null)
      })
      .catch(error => {
        // FIXME
      })
  }

  return (
    (auth.user && auth.user.authenticated) ?
      <div>
        <Button id="btn-signout" variant="outline-secondary" className="mr-sm-2"
          onClick={signout}
        >Logout</Button>
      </div>
      : <div>
        <LinkContainer to="/signin">
          <Button variant="outline-secondary" className="mr-sm-2">Sign in</Button>
        </LinkContainer>
        <LinkContainer to="/signup">
          <Button variant="outline-info">Sign up</Button>
        </LinkContainer>
      </div>
  )
}

export default Header
