import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { Container, Button, Form, Col } from 'react-bootstrap'

import Notification from './Notification'
import AuthService from '../services/auth'
import { useAuth } from '../contexts/auth'

function Signin() {
  let auth = useAuth()

  const [response, setResponse] = useState(undefined)
  const [redirect, setRedirect] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  function handleSubmit(event) {
    event.preventDefault()

    AuthService.signin(username, password)
      .then(([err, data]) => {
        if (err) {
          if ('status' in err && err.status === 400)
            setResponse(err.data)
          else {
            console.error(err)
            let message = ('data' in err && 'message' in err.data) ?
                err.data.message :
                'Something went wrong'
            setResponse({message})
          }

        } else {
          const user = data.data
          auth.updateUser(user)
          setRedirect(true)
        }
      })
      .catch(error => {
        setResponse(error)
      })
  }

  if (redirect) {
    return <Redirect to='/' />
  }

  return (
    <Container className="mt-3">
      <Notification data={response}/>

      <div className="mb-3">
        <Form noValidate onSubmit={handleSubmit}>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <Form.Label htmlFor="inputUsername" srOnly>
                Username mail address
              </Form.Label>
              <Form.Control
                className="mb-2"
                id="inputUsername"
                name="username"
                placeholder="Username or email address"
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoFocus={true}
                required minLength="6" maxLength="50"
              />
            </Col>
            <Col xs="auto">
              <Form.Label htmlFor="inputPassword" srOnly>
                Password
              </Form.Label>
              <Form.Control
                type="password"
                className="mb-2"
                id="inputPassword"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required minLength="16" maxLength="255"
              />
            </Col>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                id="checkRememberMe"
                className="mb-2"
                label="Remember me"
              />
            </Col>
            <Col xs="auto">
              <Button id="btn-signin-submit" type="submit" className="mb-2">
                Submit
              </Button>
            </Col>
          </Form.Row>

        </Form>
      </div>
    </Container>
  )
}

export default Signin
