import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Header from './Header'
import Catalog from './Catalog'
import Error from './Error'
import EmailVerification from './EmailVerification'
import FileUpload from './FileUpload'
import Footer from './Footer'
import NotFound from './NotFound'
import Signin from './Signin'
import Signup from './Signup'

import { ProvideAuth } from '../contexts/auth'

function App(props) {

  return (
    <React.Fragment>
      <ProvideAuth>

        <Router>
          <div className="d-flex flex-column min-vh-100">
            <Header />
            <div className="flex-fill" id="content">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/catalog" component={Catalog} />
                <Route path="/upload" component={FileUpload} />
                <Route path="/signin" component={Signin} />
                <Route path="/signup" component={Signup} />
                <Route path="/account/verify/:token" component={EmailVerification} />
                <Route path="/error" component={Error} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>

      </ProvideAuth>
    </React.Fragment>
  )
}

const Home = () => {
  return (
    <div className="container">
      <h2>Home</h2>
    </div>
  )
}

export default App
