const baseUrl = process.env.NODE_ENV === "production" ?
      "https://castie.foudil.fr" :
      "http://localhost:8080"

const imgUrl = baseUrl + '/img'

export default {
  baseUrl: baseUrl,
  imgUrl: imgUrl,
};
