import React from 'react'
import _ from 'lodash'

function Notification(props) {
  if (_.isEmpty(props.data)) return null

  let data = props.data

  if ('status' in data && data.status && _.isArray(data.data)) {
    const listItems = data.data.map((upload) => {
      let url = upload.url ?
        <span> — <b>{upload.url}</b></span> :
        ''
      let msg = upload.message ?
        <span> — <em>{upload.message}</em></span> :
        ''
      return <li key={upload.md5}>
        {upload.title}{url}{msg}
      </li>
    })

    return (
      <div className="alert alert-success" role="alert">
        {data.message}
        <ul>{listItems}</ul>
      </div>
    )

  } else {
    return (
      <div className="alert alert-danger" role="alert">
        {data.message}
      </div>
    )
  }

}

export default Notification
