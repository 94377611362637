import './Footer.css'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>About</h6>
            <p className="text-justify">Castie.fun <i>AWESOME VIDEOS </i> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam egestas dolor at pharetra blandit. Praesent eleifend blandit lacus. Curabitur convallis vulputate neque.</p>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Categories</h6>
            <ul className="footer-links">
              <li><a href="/category/c-language/">C</a></li>
              <li><a href="/category/front-end-development/">UI Design</a></li>
              <li><a href="/category/back-end-development/">PHP</a></li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li><a href="/about/">About us</a></li>
              <li><a href="/privacy/">Privacy</a></li>
              <li><a href="/imprint/">Imprint</a></li>
            </ul>
          </div>
        </div>
        <hr/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2021 All Rights Reserved by <a href="#home">Castie</a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" href="https://facebook.com"><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li><a className="twitter" href="https://twitter.com"><FontAwesomeIcon icon={faTwitter} /></a></li>
              <li><a className="linkedin" href="https://linkedin.com"><FontAwesomeIcon icon={faLinkedin} /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
