import axios from 'axios'
import { handleResponse } from './helpers'

const AccountService = {

  async verifyEmailToken(token) {
    return handleResponse(
      axios.get(`/api/account/verify/${token}`)
    )
  },
}

export default AccountService
