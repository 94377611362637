import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'

import Notification from './Notification'

import VideosService from '../services/videos'
import { useAuth } from '../contexts/auth'

function FileUpload(props) {
  const [response, setResponse] = useState(undefined)
  const [videoTitle, setVideoTitle] = useState('')
  const [videoDescription, setVideoDescription] = useState('')
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [fileName, setFileName] = useState('Select video file')
  const [progress, setProgress] = useState(0)
  const [validated, setValidated] = useState(false)

  let history = useHistory()
  let auth = useAuth()

  function handleFileChange(event) {
    setSelectedFiles(event.target.files)
    setFileName(event.target.files[0].name)
  }

  function handleSubmit(event) {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()

      setValidated(true)
    } else {
      uploadFile()
    }

  }

  function uploadFile() {
    let file = selectedFiles[0]
    let metadata = [{
      title: videoTitle,
      description: videoDescription,
    }]

    setProgress(0)
    setValidated(true)

    VideosService.upload(file, metadata, (event) =>
      setProgress(Math.round((event.loaded / event.total) * 100))
    )
      .then((response) =>
        setResponse(response.data)
      )
      .catch((err) => {
        let resp = {}
        try {
          resp = JSON.parse(err.request.response)
        } catch (err) { }
        setResponse(resp)
        setProgress(0)
      })
  }

  function handleReset(event) {
    setResponse(undefined)
    setVideoTitle('')
    setVideoDescription('')
    setSelectedFiles(undefined)
    setFileName('Select video file')
    setProgress(0)
    setValidated(false)
  }

  if (!(auth.user && auth.user.authenticated)) return (
    <>
      {history.push('/signin')}
    </>
  )

  return (
    <div className="container">
      <Notification data={response}/>

      <div className="mb-3">
        {/* html5 sufficient for now. Use Formik.onSubmit to apply changes after validation. */}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <fieldset className="mt-3">
            <div className="mb-3">
              <Form.Group controlId="formGroupTitle">
                <Form.Label>Video title</Form.Label>
                <Form.Control
                  type="text"
                  name="videoTitle"
                  value={videoTitle}
                  onChange={e => setVideoTitle(e.target.value)}
                  required minLength="3" maxLength="255"
                />
                <Form.Control.Feedback type="invalid">
                  Video title is required and must be at least 3 characters long.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="mb-3">
              <Form.Group controlId="formGroupDescription">
                <Form.Label>Video description</Form.Label>
                <Form.Control
                  name="videoDescription"
                  as="textarea" rows={3}
                  value={videoDescription}
                  onChange={e => setVideoDescription(e.target.value)}
                  maxLength="255"
                />
              </Form.Group>
            </div>
            <div className="mb-3">
              <Form.Group controlId="formGroupFile">
                <Form.File
                  id="custom-file"
                  label={fileName}
                  custom
                  accept="video/*"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
            </div>
            <div className="mb-3">
              <ProgressBar now={progress}/>
            </div>

            <Button
              id="btn-upload-submit"
              variant="primary" type="submit"
              disabled={!selectedFiles}
            >Upload</Button>{' '}
            <Button
              id="btn-upload-reset"
              variant="secondary" type="reset"
              onClick={handleReset}
            >Reset</Button>
          </fieldset>
        </Form>
      </div>

    </div>
  )
}

export default FileUpload
