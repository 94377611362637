// https://reactrouter.com/web/example/auth-workflow
// https://www.tameemiftikhar.com/blog/2019/11/11/authentication-with-react-context.html
// https://www.reddit.com/r/reactjs/comments/lf3fi9/react_context_with_authentication/
import { createContext, useContext, useState, useEffect } from 'react'
import AuthService from '../services/auth'

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const updateUser = user => setUser(user)

  useEffect(() => {
    AuthService.status()
      .then(([err, data]) => {
        if (err) {
          console.error(err)
        } else {
          const user = data.data
          console.log(user)
          setUser(user)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, []);

  return {
    user,
    updateUser,
  }
}
